<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
      <validation-observer ref="VFormCPPT">
        <b-form @submit.prevent="doSubmit" class="card">
          <div class="card-header">
            <h6 class="card-title font-weight-semibold">Tambah CPPT ADIME</h6>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Tanggal</label>
                  <div class="input-group">
                    <datepicker v-model="row.arancpdi_tanggal" input-class="form-control transparent"
                      placeholder="Pilih Tanggal" class="my-datepicker"
                      calendar-class="my-datepicker_calendar">
                    </datepicker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i
                          class="icon-calendar"></i></span>
                    </div>
                  </div>
                  <VValidate name="Tanggal" v-model="row.arancpdi_tanggal"
                    :rules="toValidate(mrValidation.arancpdi_tanggal)" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="" for="">Jam</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                    <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancpdi_jam">
                    </vue-timepicker>
                  </div>
                  <VValidate name="Jam" v-model="row.arancpdi_jam"
                    :rules="toValidate(mrValidation.arancpdi_jam)" />
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="card">
              <div class="card-header p-0">
                <a @click="activeA = !activeA" href="javascript:;" class="btn btn-block text-left" data-toggle="collapse">
                  <div class="label_code">
                    <h2>A</h2>
                  </div>
                  <span class="ml-2">Antropometri</span>
                </a>
              </div>
              <div :class="activeA ? 'collapse show' : 'collapse'" id="dietAntro">
                <div class="wrap_line mb-0">
                  <div>
                    <div class="row">
                      <!-- Pasien Dewasa (>13 tahun) -->
                      <!-- v-if="row.ap_usia > 13" -->
                      <div class="col-md-6" v-if="row.ap_usia > 13">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>BB<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bb" @input="countBBIdeal()" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                  <VValidate name="BB" v-model="row.arancpdi_bb"
                                    :rules="toValidate(mrValidation.arancpdi_bb)" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>TB<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_tb" @input="countBBIdeal()" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                  </div>
                                  <VValidate name="TB" v-model="row.arancpdi_tb"
                                    :rules="toValidate(mrValidation.arancpdi_tb)" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bbi" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                  <VValidate name="BB Ideal" v-model="row.arancpdi_bbi"
                                    :rules="toValidate(mrValidation.arancpdi_bbi)" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>% BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bbi100" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                  </div>
                                  <VValidate name="% BB Ideal" v-model="row.arancpdi_bbi100"
                                    :rules="toValidate(mrValidation.arancpdi_bbi100)" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>IMT<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_imt" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg/m2</span>
                                    </div>
                                  </div>
                                  <VValidate name="IMT" v-model="row.arancpdi_imt"
                                    :rules="toValidate(mrValidation.arancpdi_imt)" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Status Gizi <small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <v-select placeholder="-- Plih Salah Satu --" v-model="row.arancpdi_status_gizi"
                                  :options="Config.mr.statusGiziRanap" label="text" :clearable="true"
                                  :reduce="v=>v.value"></v-select>
                                  <VValidate name="Status Gizi" v-model="row.arancpdi_status_gizi"
                                    :rules="toValidate(mrValidation.arancpdi_status_gizi)" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Pasien Dewasa (<13 tahun) -->
                      <!-- v-else -->
                      <div class="col-md-6" v-else>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>BB<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bb" @input="countBBIdeal()" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                  <VValidate name="BB" v-model="row.arancpdi_bb"
                                    :rules="toValidate(mrValidation.arancpdi_bb)" />
                                </div>
                              </div>
                            </div>
                          </div>

                          

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>TB<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_tb" @input="countBBIdeal()" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                  </div>
                                  <VValidate name="TB" v-model="row.arancpdi_tb"
                                    :rules="toValidate(mrValidation.arancpdi_tb)" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bbi" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                  </div>
                                  <VValidate name="BB Ideal" v-model="row.arancpdi_bbi"
                                    :rules="toValidate(mrValidation.arancpdi_bbi)" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>% BB Ideal<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="input-group">
                                    <b-form-input :formatter="number" v-model="row.arancpdi_bbi100" type="text"
                                      class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                  </div>
                                  <VValidate name="% BB Ideal" v-model="row.arancpdi_bbi100"
                                    :rules="toValidate(mrValidation.arancpdi_bbi100)" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Z Score<small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <v-select placeholder="-- Plih Salah Satu --" @input="selectStatusGizi($event,Config.mr.zScore)" v-model="row.arancpdi_z_score"
                                    :options="Config.mr.zScore" label="text" :clearable="true" :reduce="v=>v.value">
                                  </v-select>
                                </div>
                              </div>
                              <VValidate name="Z Score" v-model="row.arancpdi_z_score"
                                :rules="toValidate(mrValidation.arancpdi_z_score)" />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Status Gizi <small class="txt_mandatory">*</small></label>
                              <div class="form-row">
                                <div class="col-md-12">
                                  <v-select v-model="row.arancpdi_status_gizi" :disabled="true"
                                  :options="Config.mr.statusGiziRanap" label="text" :clearable="true"
                                  :reduce="v=>v.value"></v-select>
                                  <VValidate name="Status Gizi" v-model="row.arancpdi_status_gizi"
                                    :rules="toValidate(mrValidation.arancpdi_status_gizi)" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="wrap_line">
                          <h3><span>Fisik/Klinis</span></h3>
                          <div>
                            <b-form-radio-group :options="Config.mr.yesNoOptKeluhan"
                              v-model="row.arancpdi_fisik_klinis" />
                            <VValidate name="Fisik/Klinis" v-model="row.arancpdi_fisik_klinis"
                              :rules="toValidate(mrValidation.arancpdi_fisik_klinis)" />
                          </div>

                          <div class="row mt-3" v-if="row.arancpdi_fisik_klinis == 'Y'">
                            <div class="col-md-12 mb-2">
                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  class="checkbox-inline"
                                  name="Diare"
                                  v-model="row.arancpdi_is_diare"
                                  value="Y"
                                  unchecked-value="N"  
                                >Diare</b-form-checkbox>
                                <div class="ml-2" v-if="row.arancpdi_is_diare == 'Y'">
                                  <b-form-input :formatter="normalText" v-model="row.arancpdi_is_diare_text" type="text" class="form-control" />
                                  <VValidate name="Diare" v-model="row.arancpdi_is_diare_text"
                                    :rules="toValidate(mrValidation.arancpdi_is_diare_text)" />
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  class="checkbox-inline"
                                  name="Muntah"
                                  v-model="row.arancpdi_is_muntah"
                                  value="Y"
                                  unchecked-value="N"  
                                >Muntah</b-form-checkbox>
                                <div class="ml-2" v-if="row.arancpdi_is_muntah == 'Y'">
                                  <b-form-input :formatter="normalText" v-model="row.arancpdi_is_muntah_text" type="text" class="form-control" />
                                  <VValidate name="Muntah" v-model="row.arancpdi_is_muntah_text"
                                    :rules="toValidate(mrValidation.arancpdi_is_muntah_text)" />
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  class="checkbox-inline"
                                  name="Konstipasi"
                                  v-model="row.arancpdi_is_konstipasi"
                                  value="Y"
                                  unchecked-value="N"  
                                >Konstipasi</b-form-checkbox>
                                <div class="ml-2" v-if="row.arancpdi_is_konstipasi == 'Y'">
                                  <b-form-input :formatter="normalText" v-model="row.arancpdi_is_konstipasi_text" type="text" class="form-control" />
                                  <VValidate name="Konstipasi" v-model="row.arancpdi_is_konstipasi_text"
                                    :rules="toValidate(mrValidation.arancpdi_is_konstipasi_text)" />
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  class="checkbox-inline"
                                  name="Mual"
                                  v-model="row.arancpdi_is_mual"
                                  value="Y"
                                  unchecked-value="N"  
                                >Mual</b-form-checkbox>
                                <div class="ml-2" v-if="row.arancpdi_is_mual == 'Y'">
                                  <b-form-input :formatter="normalText" v-model="row.arancpdi_is_mual_text" type="text" class="form-control" />
                                  <VValidate name="Mual" v-model="row.arancpdi_is_mual_text"
                                    :rules="toValidate(mrValidation.arancpdi_is_mual_text)" />
                                </div>
                              </div>

                              <div class="d-flex mb-2">
                                <b-form-checkbox
                                  class="checkbox-inline"
                                  name="Lainnya"
                                  v-model="row.arancpdi_is_lainnya"
                                  value="Y"
                                  unchecked-value="N"  
                                >Lainnya</b-form-checkbox>
                                <div class="ml-2" v-if="row.arancpdi_is_lainnya == 'Y'">
                                  <b-form-input :formatter="normalText" v-model="row.arancpdi_is_lainnya_text" type="text" class="form-control" />
                                  <VValidate name="Lainnya" v-model="row.arancpdi_is_lainnya_text"
                                    :rules="toValidate(mrValidation.arancpdi_is_lainnya_text)" />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <b-form-checkbox
                                class="checkbox-inline"
                                name="Gangguan Menelan"
                                v-model="row.arancpdi_is_gangguan_menelan"
                                value="Y"
                                unchecked-value="N"  
                              >Gangguan Menelan</b-form-checkbox>
                              
                              <b-form-checkbox
                                class="checkbox-inline"
                                name="Konstipasi"
                                v-model="row.arancpdi_is_konstipasi"
                                value="Y"
                                unchecked-value="N"  
                              >Konstipasi</b-form-checkbox>
                              
                              <b-form-checkbox
                                class="checkbox-inline"
                                name="Gangguan Mengunyah"
                                v-model="row.arancpdi_is_gangguan_mengunyah"
                                value="Y"
                                unchecked-value="N"  
                              >Gangguan Mengunyah</b-form-checkbox>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header p-0">
                <a @click="activeD = !activeD" href="javascript:;" class="btn btn-block text-left" data-toggle="collapse">
                  <div class="label_code">
                    <h2>D</h2>
                  </div>
                  <span class="ml-2">Diagnosis</span>
                </a>
              </div>
              <div :class="activeD ? 'collapse show' : 'collapse'" id="dietAntro">
                <div class="wrap_line mb-0">
                  <div>
                    <div class="row">
                      <div class="col-md-8">
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th width="50%">Diagnosis Gizi</th>
                              <th width="10%">Hapus</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (row.arancpdi_diagnosis||[])" :key="k">
                              <td>
                                <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                  <label for="">Diagnosis Gizi</label>
                                  <v-select @input="selectIntervensi($event,mICDGizi,'arancpdi_diagnosis',k)" placeholder="-- Plih Salah Satu --" v-model="v.id"
                                    :options="mICDGizi" label="text" :clearable="true" :reduce="v=>v.value">
                                  </v-select>
                                  <!--
                                  <VValidate :name="'Diagnosis Gizi #'+(k+1)"
                                  v-model="v.id"
                                  :rules="{required: 1}" />
                                  -->
                                </div>
                                <div class="form-group">
                                  <label>Diagnosis Gizi</label>
                                  <textarea v-model="v.text" rows="3" class="form-control" ></textarea>
                                  <VValidate :name="'Diagnosis Gizi Ket #'+(k+1)"
                                  v-model="v.text"
                                  :rules="{required: 1, min: 3, max: 128}" />
                                </div>
                              </td>
                              <td>
                                <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arancpdi_diagnosis',k)" data-toggle="tooltip" data-placement="top"
                                    title="Delete"><i class="icon-bin"></i></a>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td class="text-center" colspan="99">
                                <a href="javascript:;" @click="addIntervensi('arancpdi_diagnosis')" class="btn mb-2  alpha-blue">
                                  <i class="icon-plus2 mr-2"></i>
                                  Tambah
                                </a>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header p-0">
                <a @click="activeI = !activeI" href="javascript:;" class="btn btn-block text-left" data-toggle="collapse">
                  <div class="label_code">
                    <h2>I</h2>
                  </div>
                  <span class="ml-2">Intervensi</span>
                </a>
              </div>
              <div :class="activeI ? 'collapse show' : 'collapse'" id="dietIntervensi">
                <div class="wrap_line mb-0">
                  <div>
                    <div class="row">
                      <div class="col-md-8">
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th width="50%">Intervensi</th>
                              <th width="10%">Hapus</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (row.arancpdi_intervensi||[])" :key="k">
                              <td>
                                <div class="diagnoseElement form-group" data-doc-diagnose="icd10">
                                  <label for="">Intervensi Gizi </label>
                                  <v-select @input="selectIntervensi($event,mICDGiziTindakan,'arancpdi_intervensi',k)" placeholder="-- Plih Salah Satu --" v-model="v.id"
                                    :options="mICDGiziTindakan" label="text" :clearable="true" :reduce="v=>v.value">
                                  </v-select>
                                  
                                  <!--
                                  <VValidate :name="'Intervensi Gizi #'+(k+1)"
                                  v-model="v.id"
                                  :rules="{required: 1}" />
                                  -->
                                </div>
                                <div class="form-group">
                                  <label>Intervensi Gizi </label>
                                  <textarea v-model="v.text" rows="3" class="form-control" ></textarea>
                                  <VValidate :name="'Intervensi Gizi Ket #'+(k+1)"
                                  v-model="v.text"
                                  :rules="{required: 1, min: 3, max: 128}" />
                                </div>
                              </td>
                              <td>
                                <a href="javascript:;" class="list-icons-item"
                                    @click="deleteIntervensi('arancpdi_intervensi',k)" data-toggle="tooltip" data-placement="top"
                                    title="Delete"><i class="icon-bin"></i></a>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td class="text-center" colspan="99">
                                <a href="javascript:;" @click="addIntervensi('arancpdi_intervensi')" class="btn mb-2  alpha-blue">
                                  <i class="icon-plus2 mr-2"></i>
                                  Tambah
                                </a>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header p-0">
                <a @click="activeM = !activeM" href="javascript:;" class="btn btn-block text-left" data-toggle="collapse">
                  <div class="label_code">
                    <h2>M</h2>
                  </div>
                  <span class="ml-2">Monitoring</span>
                </a>
              </div>
              <div :class="activeM ? 'collapse show' : 'collapse'" id="dietMonitoring">
                <div class="wrap_line mb-0">
                  <div>
                    <div class="row">
                      <div class="col-md-8">
                        <div>
                          <label for="monitoringGizi">Monitoring <strong class="text-danger">*</strong></label>
                          <b-form-textarea v-model="row.arancpdi_monitoring" :formatter="normalText" name="Montoring" id="Montoring" rows="3" class="form-control"></b-form-textarea>
                          <VValidate name="Montoring" v-model="row.arancpdi_monitoring"
                            :rules="toValidate(mrValidation.arancpdi_monitoring)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header p-0">
                <a href="javascript:;" @click="activeI = !activeI" class="btn btn-block text-left" data-toggle="collapse">
                  <div class="label_code">
                    <h2>E</h2>
                  </div>
                  <span class="ml-2">Evaluasi</span>
                </a>
              </div>
              <div :class="activeM ? 'collapse show' : 'collapse'" id="dietEvaluasi">
                <div class="wrap_line mb-0">
                  <div>
                    <div class="row">
                      <div class="col-md-8">
                        <div>
                          <label for="evaluasiGizi">Evaluasi <strong class="text-danger">*</strong></label>
                          <b-form-textarea v-model="row.arancpdi_evaluasi" :formatter="normalText" name="Evaluasi" id="Evaluasi" rows="3" class="form-control"></b-form-textarea>
                          <VValidate name="Evaluasi" v-model="row.arancpdi_evaluasi"
                            :rules="toValidate(mrValidation.arancpdi_evaluasi)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker,PatientInfo },
  data(){
    return {
      rowReg: {},
      activeAnat: 0,
      activeA: true,
      activeD: true,
      activeI: true,
      activeM: true,
      activeE: true,
      
      patientData: {},
      loading: {
        patientInfo: false,
      },

      mICDGizi: [],
      mICDGiziTindakan: [],
    }
  },
  computed: {
    
  },
  methods: {
    
    calculateAgeInMonths(birthdate) {
      const birth = new Date(birthdate)
      const current = new Date()
      let yearsDifference = current.getFullYear() - birth.getFullYear()
      let monthsDifference = current.getMonth() - birth.getMonth()
      if (monthsDifference < 0) {
        yearsDifference--
        monthsDifference += 12
      }
      const totalMonths = yearsDifference * 12 + monthsDifference
      return totalMonths - 1
    },
    countBBIdeal(){
      let bbIdeal = 0
      // arancpdi_bb
      // arancpdi_tb
      
      let usia = this.row.ap_usia

      if(usia < 1){
        let bulan = this.calculateAgeInMonths(this.row.ap_dob)
        bbIdeal = (bulan + 9) / 2
      }else if(usia >= 1 && usia < 7){
        bbIdeal = 2 * (usia) + 8
      }else if(usia >= 7 && usia < 14){
        bbIdeal = (7 * (usia) - 5 ) / 2
      }else{
        bbIdeal = (this.row.arancpdi_tb - 100) * 0.9
      }
      
      this.row.arancpdi_bbi = bbIdeal
      this.row.arancpdi_bbi = this.row.arancpdi_bbi.toFixed(2)

      this.row.arancpdi_bbi100 = this.row.arancpdi_bb/bbIdeal * (100/100)
      this.row.arancpdi_bbi100 = this.row.arancpdi_bbi100 * 100
      this.row.arancpdi_bbi100 = this.row.arancpdi_bbi100.toFixed(2)

      this.row.arancpdi_imt = this.row.arancpdi_bb / ((this.row.arancpdi_tb/100) * (this.row.arancpdi_tb/100))
      
      this.row.arancpdi_imt = this.row.arancpdi_imt.toFixed(2)
      
    },
    selectStatusGizi(value,master){
      if(value){
        let index = (master || []).findIndex(x => x.value == value)          
        if (index !== -1) {
          this.row.arancpdi_status_gizi = master[index]['desc']
        }
      }else{
        this.row.arancpdi_status_gizi = null
      }
    },
    selectIntervensi(value,master,field,k){
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)          
        if (index !== -1) {
          this.row[field][k]['name'] = master[index]['text']
          this.row[field][k]['text'] = master[index]['description']
        }
      }else{
        this.row[field][k]['name'] = null
      }
    },
    deleteIntervensi(field,k){
      this.row[field].splice(k,1)
    },
    addIntervensi(field){
      this.row[field].push({
        id: null,
        name: null,
        text: null
      })
    },
    back() {
      this.$router.back()
    },

    apiGet(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.cpptNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.cpptNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(this.row.arancpdi_bb && this.row.arancpdi_tb){
            this.countBBIdeal()          
          }
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.cpptNo){
        Gen.apiRest(
            "/get/"+url+'?master=1', 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
      }    
    },
    
    getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'

      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

  
    toValidate(val) {
      return {
        ...val
      }
    },
    
    doSubmit(){

      if(this.row.arancp_is_active == 'N'){
        return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
        }).then(result => {
            if (result.value) {
              setTimeout(()=>{
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for(let i = 0; i < (el||[]).length; i++){
                      if(el[i].style.display !== 'none'){
                          inv.push(el[i].id)
                      }
                  }
                  if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
              },500)
            }
        })
      }

      if(!(this.row.arancpdi_diagnosis||[]).length){
        return this.$swal({
          icon: 'error',
          title: 'Tidak Dapat Lanjut',
          text: 'Diagnosis Belum Diisi'
        })
      }

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        
        
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: 'RanapCPPTList' }).catch(()=>{})   
                  })
              })
            }
          })
        }
        
      })
    },
  },
  mounted() {
    this.apiGet()

    this.getPatientInfo()    
  },
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}
</script>